import { Controller } from 'stimulus';
import $ from 'jquery';

import Select2 from 'select2';
import Rails from '@rails/ujs';

import 'select2/dist/css/select2.css';
import axios from 'axios';

export default class extends Controller {
  static targets = [
    'selected',
    'customSelect',
    'leadCustomSelect',
    'options',
    'researchField',
    'optionsValues',
    'formationShiftsContainer',
  ];

  isLeadInput = false;

  connect() {
    $('.user-tags-select').select2({
      multiple: true,
      tags: false,
      tokenSeparators: [',', ' '],
      selectionCssClass: 'all',
      // dropdownCssClass: 'all'
    });

    $(".js-example-placeholder-multiple").select2({
      placeholder: "Select a state"
  });
    $('.admin-select').select2({
      multiple: true,
    });
    $('.user-tags-select-single').select2({});

    if (document.getElementById('custom_lead_status_id')) {
      document
        .getElementById('lead_status_id')
        .addEventListener('change', (e) => {
          this.updateForm(e.target.value);
        });
    }

  }

  toggleLeadSelect() {
    this.leadCustomSelectTarget.parentNode.classList.toggle(
      'custom-select--qualify-lead-active'
    );
    this.optionsTarget.classList.toggle('custom-select__options--hidden');
    this.isLeadInput = true;
    this.closeOtherSelects(this.leadCustomSelectTarget);
  }

  toggleSelect() {
    this.customSelectTarget.classList.toggle('custom-select--active');
    this.selectedTarget.classList.toggle('custom-select__text');
    this.optionsTarget.classList.toggle('custom-select__options--hidden');
    this.closeOtherSelects(this.customSelectTarget);
  }

  selectOption({ target: option }) {
    const selectedOption = option;

    this.selectedTarget.innerHTML = selectedOption.innerHTML;
    this.isLeadInput
      ? this.updateLeadHiddenSelect(selectedOption)
      : this.updateHiddenSelect(selectedOption);
    this.isLeadInput ? this.toggleLeadSelect() : this.toggleSelect();
  }

  updateLeadHiddenSelect({ dataset: data }) {
    if (data.type === 'lead_status_id') {
      document.getElementById(`${data.type}`).value = parseInt(data.value, 10);
    } else {
      document.getElementById(data.type).value = data.value;
    }
    const event = document.createEvent('HTMLEvents');
    event.initEvent('change', true, true);
    document.getElementById(data.type).dispatchEvent(event);
  }

  updateHiddenSelect({ dataset: data }) {
    if (data.type !== 'select-dates') {
      document.getElementById(`${data.type}`).value = parseInt(data.value, 10);
    } else {
      document.getElementById(`${data.type}`).value = data.value;
    }
  }

  closeSelectFromEverywhere() {
    const customSelectsOptions = document.querySelectorAll(
      '[class*="custom-select__options"]'
    );
    customSelectsOptions.forEach((customSelectOptions) => {
      if (
        !customSelectOptions.classList.value.includes(
          'custom-select__options--hidden'
        )
      ) {
        customSelectOptions.classList.add('custom-select__options--hidden');
        if( customSelectsOptions.previousSibling) {
          customSelectOptions.previousSibling.firstChild.classList.remove(
            'custom-select__text'
          );
          customSelectOptions.previousSibling.classList.remove(
            'custom-select--active'
          );
        } 
        customSelectOptions.parentNode.classList.remove(
          'custom-select--qualify-lead-active'
        );
      }
    });
  }

  closeOtherSelects(customSelect) {
    const customSelectsOptions = document.querySelectorAll(
      '.custom-select__options'
    );
    customSelectsOptions.forEach((customSelectOptions) => {
      if (customSelectOptions !== customSelect.parentNode.lastChild) {
        customSelectOptions.classList.add('custom-select__options--hidden');
        customSelectOptions.previousSibling.firstChild.classList.remove(
          'custom-select__text'
        );
        customSelectOptions.previousSibling.classList.remove(
          'custom-select--active'
        );
      }
    });
  }

  research() {
    const optionsValues = this.optionsValuesTarget;
    const researchField = this.researchFieldTarget;
    const mappedIds = this.mapValuesAndIds(researchField.dataset.ids);
    let filtered = researchField.dataset.inputs
      .replace(/["\[\]]/g, '')
      .split(',');
    if (researchField.value) {
      this.filter(
        filtered,
        researchField,
        optionsValues,
        researchField.dataset.type,
        mappedIds
      );
    }
  }

  filter(filtered, researchField, optionsValues, type, mappedIds) {
    filtered = filtered.filter((value) =>
      value
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .includes(researchField.value.toLowerCase())
    );
    optionsValues.innerHTML = '';
    filtered.forEach((filteredValue) => {
      optionsValues.insertAdjacentHTML(
        'afterbegin',
        `<span class="text text--semi-bold text--smaller custom-select__option" data-action="click->select#selectOption" data-type="${type}" data-value="${
          mappedIds[filteredValue.trim()]
        }">${filteredValue}</span>`
      );
    });
  }

  mapValuesAndIds(data) {
    const parsedData = JSON.parse(data);
    let object = {};
    parsedData.forEach((valuesPair) => {
      let key = valuesPair[0].trim();
      let value = valuesPair[1];
      object[key] = value;
    });
    return object;
  }

  updateForm(status_id) {
    if (gon.statuses[status_id] === 'QualifiedStatus') {
    } else {
      document.querySelectorAll('.input').forEach((input) => {
        input.required = false;
      });
    }
  }

  checkFilledFields() {
    const label = document.querySelector('.lead-card__fill-label');
    const statusField = document.getElementById('lead_status_id');
    for (const input of document.querySelectorAll("[required]")) {
      if (
        input.value === '' &&
        gon.statuses[statusField.value] !== 'UnqualifiedStatus'
      ) {
        label.classList.remove('lead-card__fill-label--hidden');
        break;
      } else {
        label.classList.add('lead-card__fill-label--hidden');
      }
    }
  }

  updateFormationShifts(e) {
    const domElement = this.formationShiftsContainerTarget;
    const inputs = [...domElement.querySelectorAll('span, input')];
    for (var input of inputs) {
      input.remove();
    }
    gon.formation_shifts[e.target.value].forEach((formationShift) => {
      let checkbox = document.createElement('input');
      checkbox.type = 'radio';
      checkbox.classList.add('card-item__scheduling-button--hour');
      checkbox.value = formationShift.id;
      checkbox.name = 'shift';
      checkbox.dataset.hour = `${new Date(
        formationShift.date
      ).toLocaleTimeString(navigator.language, {
        hour: '2-digit',
        minute: '2-digit',
      })}h`;
      domElement.appendChild(checkbox);
    });
  }

  unlockSubmit() {
    const { elements } = document.forms.formation_shift_booking;
    let { day: days } = elements;
    let { shift: shifts } = elements;
    days.length === undefined ? (days = new Array(days)) : (days = [...days]);
    shifts.length === undefined
      ? (shifts = new Array(shifts))
      : (shifts = [...shifts]);
    let result = new Array();
    days.map((element) => {
      result.push(element.checked);
    });

    shifts.map((element) => {
      result.push(element.checked);
    });
    result = result.filter((item) => item === true);
    if (result.length === 2) {
      elements.commit.classList.remove('button--disabled');
    } else {
      elements.commit.classList.add('button--disabled');
    }
  }
}
