import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'reminder',
    'callableReminder',
    'reminderLabel',
    'callableReminderLabel',
    'tab',
    'messages',
    'chatPreview',
    'tabs',
    'image',
    'documentsContainer',
    'addLeadsInput',
    'addLeadsSubmit',
    'menu',
    'downloadLeads',
  ];
  static values = { phone: String };

  connect() {
    if (window.location.pathname.includes('/chats')) {
      const messages = this.messagesTarget;
      if (messages) {
        this.scrollMessages(messages);
      }
      messages.addEventListener('DOMNodeInserted', () => {
        this.scrollMessages(messages);
      });
    }
  }

  displayCta(target) {
    const phone = target.dataset.phone;
    const icon = target.dataset.icon;
    target.dataset.action = 'click->show-modal#displayModal';
    if (target.dataset.link != 'false') {
      target.innerHTML = `<a href="tel:${phone}"><img class="icon--sized-medium" src="${icon}"></a>`;
    } else {
      target.innerHTML = `<img class="icon--sized-medium icon--spaced-right-tiny" src="${icon}"><p class="text text--centered">Rappeler quand même ?</p>`;
    }
    target.classList.add('lead-card__call--active');
  }

  animateCallableReminder() {
    const target = this.callableReminderTarget;
    this.displayCta(target);
  }

  toggleChats(e) {
    if (!e.target.classList.value.includes('chats-switch__active')) {
      this.tabTargets.forEach((tab) => {
        tab.classList.toggle('chats-switch__active');
      });
    }
  }

  toggleTabs(e) {
  this.tabsTargets.forEach((tab) => {
    tab.classList.remove('portfolio-tabs--active');
  });

  e.target.closest('a').classList.add('portfolio-tabs--active');
}

  scrollMessages(messages) {
    messages.childNodes[messages.childNodes.length - 1].scrollIntoView();
  }

  highlightChatPreview(e) {
    const chatsPreview = this.chatPreviewTargets;
    const { target } = e;
    chatsPreview.forEach((chatPreview) => {
      chatPreview.classList.remove('chats-list__card--active');
    });
    target.classList.add('chats-list__card--active');
  }

  animateNewLeadButton(e) {
    const image = this.imageTarget;
    const src = image.src;
    image.src = image.dataset.gif;
    setTimeout(() => {
      image.src = src;
    }, 4000);
  }

  removeDocumentsContainer() {
    this.documentsContainerTarget.remove();
  }

  disableButton({ currentTarget }) {
    currentTarget.classList.add('button--disabled');
  }

  authorizeAddLeadsInput() {
    const addLeadsInput = this.addLeadsInputTarget;
    const addLeadsSubmit = this.addLeadsSubmitTarget;

    if (addLeadsInput.value != '') {
      addLeadsSubmit.classList.remove('button--disabled');
    }
  }

  toggleClientMenu() {
    const menu = this.menuTarget;
    if (menu === null) return;

    menu.classList.toggle('sidebar-clients--toggled');
  }

  animateDownloadLeadsButton() {
    const downloadLeadsButton = this.downloadLeadsTarget;
    const buttonSpan =
      downloadLeadsButton.childNodes[0].getElementsByTagName('span')[0];
    const loadingMessageContainer =
      downloadLeadsButton.childNodes[0].getElementsByTagName('div')[0];
    buttonSpan.innerHTML = `<span></span>`;
    buttonSpan.classList.add('clients-button--loader');
    downloadLeadsButton.classList.add('button--disabled');
    loadingMessageContainer.classList.remove('hidden');
  }

  hideAncestorParent({ currentTarget }) {
    currentTarget.parentElement.parentElement.classList.add('hidden');
  }
}
