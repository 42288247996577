import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'startDate',
    'endDate',
    'startSpan',
    'endSpan',
    'capacity',
    'holidayStart',
    'holidayEnd',
    'shift',
    'modifier',
  ];

  connect() {
    this.updateTimeSpan();
    if (!this.hasShiftTarget) return;
    if (this.shiftTarget.dataset.state !== 'create') return;

    this.updateFocusedSlot({ target: this.shiftTarget });
    this.setupPreview({ target: this.shiftTarget });
  }

  // Methods below : Update inputs and visible informations on screen
  updateTimeSpan() {
    if (!this.hasStartDateTarget || !this.hasEndDateTarget) return;

    const startDateObject = new Date(this.startDateTarget.value);
    const endDateObject = new Date(this.endDateTarget.value);
    this.startSpanTarget.textContent = `${startDateObject.getUTCHours()} : ${startDateObject
      .getUTCMinutes()
      .toString()
      .padStart(2, '0')}`;
    this.endSpanTarget.textContent = `${endDateObject.getUTCHours()} : ${endDateObject
      .getUTCMinutes()
      .toString()
      .padStart(2, '0')}`;

    this.updatePreview(startDateObject.getTime(), endDateObject.getTime());
  }

  updateDateInput(e) {
    e.preventDefault();
    const buttonType = e.currentTarget.dataset.buttonType;

    const dateActions = {
      addStartMinutes: { target: 'startDate', unit: 'Minutes', value: 30 },
      removeStartMinutes: { target: 'startDate', unit: 'Minutes', value: -30 },
      addStartHour: { target: 'startDate', unit: 'Hours', value: 1 },
      removeStartHour: { target: 'startDate', unit: 'Hours', value: -1 },
      addEndMinutes: { target: 'endDate', unit: 'Minutes', value: 30 },
      removeEndMinutes: { target: 'endDate', unit: 'Minutes', value: -30 },
      addEndHour: { target: 'endDate', unit: 'Hours', value: 1 },
      removeEndHour: { target: 'endDate', unit: 'Hours', value: -1 },
    };

    const action = dateActions[buttonType];
    const dateField = this[`${action.target}Target`];

    const proposedDateTime = new Date(dateField.value);
    const setter = `set${action.unit}`;
    proposedDateTime[setter](
      proposedDateTime[`get${action.unit}`]() + action.value
    );

    if (this.isValidTimeRange(proposedDateTime, action.target)) {
      const preview = document.getElementById('preview');
      if (preview) preview.style.borderColor = '#00b4fc';
      document.getElementById('hour-error')?.remove();
      dateField.value = this.formatDateTime(proposedDateTime);
      this.updateTimeSpan();
    } else {
      document.getElementById('preview').style.borderColor = '#ff7277';
      document.getElementById('hour-error')?.remove();
      e.currentTarget.parentElement.insertAdjacentHTML(
        'afterend',
        "<span id='hour-error' class='text text--bold text--colored-red'>Conflit horaire</span>"
      );
    }
  }

  updateFocusedSlot({ target }) {
    [...document.getElementsByClassName('--focused')].forEach((div) => {
      div.classList.remove('--focused');
    });
    target.classList.add('--focused');
  }

  // Thanks ChatGPT to help us formatting our dates nicely without a library
  formatDateTime(date) {
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
      2,
      '0'
    )}-${String(date.getDate()).padStart(2, '0')}T${String(
      date.getHours()
    ).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`;
  }

  isValidTimeRange(proposedDateTime, target) {
    const startDate =
      target === 'startDate'
        ? proposedDateTime
        : new Date(this.startDateTarget.value);
    const endDate =
      target === 'endDate'
        ? proposedDateTime
        : new Date(this.endDateTarget.value);

    const isInsideCalendarRange =
      startDate.getUTCHours() >= 7 && endDate.getUTCHours() <= 23;

    const isNotOverlapping = endDate - startDate >= 30 * 60000;
    const areBoundariesValid = startDate.getUTCHours() < endDate.getUTCHours();

    return isInsideCalendarRange && isNotOverlapping && areBoundariesValid;
  }

  updateCapacity({ currentTarget }) {
    const buttonType = currentTarget.dataset.buttonType;

    const capacityActions = {
      addCapacity: +1,
      removeCapacity: -1,
    };

    const modifier = capacityActions[buttonType];
    const capacityInput = this.capacityTarget;
    const capacity = parseInt(capacityInput.value);
    if (capacity + modifier < 0) return this.capacityError(capacityInput);

    this.removeCapacityError(capacityInput);
    capacityInput.value = capacity + modifier;
  }

  capacityError(capacityInput) {
    capacityInput.classList.add('capacity-error');
  }

  removeCapacityError(capacityInput) {
    if (!capacityInput.classList.contains('capacity-error')) return;

    capacityInput.classList.remove('capacity-error');
  }

  resetCapacity({ currentTarget }) {
    if (currentTarget.value >= 0) return;

    currentTarget.value = 0;
  }

  capacitySwitch({ target }) {
    const capacityInput = this.capacityTarget;
    console.log(target, target.value);
    if (parseInt(target.value) === 0) {
      capacityInput.parentElement.classList.add('--hidden');
      capacityInput.value = -1;
    } else {
      capacityInput.parentElement.classList.remove('--hidden');
      capacityInput.value = 0;
    }
  }

  swapRecursivity({ currentTarget }) {
    const allLinks = document.querySelectorAll([
      '.calendar__link--new',
      '.calendar__link--edit',
    ]);

    const calendarContainer =
      document.getElementsByClassName('calendar-container')[0];

    const recursivityField = document
      .querySelector('.calendar-editing')
      .querySelector('#recursivity');

    const recursivityDelete = document
      .querySelector('.calendar-editing')
      .querySelector('#recursivity-delete');

    if (currentTarget.checked) {
      allLinks.forEach((link) => {
        link.href = link.href.replace(
          '&recursivity=false',
          '&recursivity=true'
        );
      });
      calendarContainer.style.borderColor = '#FD8204';
      calendarContainer.style.backgroundColor = '#fbe8cc';
      if (!recursivityField) return;

      recursivityField.value = true;
      recursivityDelete.href = recursivityDelete.href.replace(
        '&recursivity=false',
        '&recursivity=true'
      );
    } else {
      allLinks.forEach((link) => {
        link.href = link.href.replace(
          '&recursivity=true',
          '&recursivity=false'
        );
      });
      calendarContainer.style.borderColor = 'transparent';
      calendarContainer.style.backgroundColor = 'transparent';
      if (!recursivityField) return;

      recursivityField.value = false;
      recursivityDelete.href = recursivityDelete.href.replace(
        '&recursivity=true',
        '&recursivity=false'
      );
    }
  }

  sanitizeHolidayInput() {
    const holidayStart = this.holidayStartTarget;
    const holidayEnd = this.holidayEndTarget;
    holidayStart.setAttribute('max', holidayEnd.value);

    holidayEnd.setAttribute('min', holidayStart.value);
  }

  checkHolidayInput(event) {
    if (
      new Date(this.holidayStartTarget.value) <
      new Date(this.holidayEndTarget.value)
    )
      return;

    event.preventDefault();
    alert('La date de début doit être antérieure à la date de fin');
  }

  planningSwitch({ currentTarget }) {
    if (currentTarget.classList.contains('--active')) return;

    for (const child of currentTarget.parentElement.children) {
      child.classList.toggle('--active');
    }
  }

  displayClaimForm({ currentTarget }) {
    if (currentTarget.dataset.claim === 'true') return;

    const containerElement =
      document.getElementsByClassName('calendar__claim')[0];
    containerElement.innerHTML = '';
    const closeElement = document.createElement('span');
    closeElement.innerHTML = 'X';
    containerElement.appendChild(closeElement);
    closeElement.addEventListener('click', () => {
      containerElement.classList.add('hidden');
    });

    if (currentTarget.dataset.deletion === 'true') {
      this.createDeletionForm(currentTarget, containerElement);
    } else {
      this.createClaimForm(currentTarget, containerElement);
    }
  }

  createDeletionForm(currentTarget, deletionFormElement) {
    const title = document.createElement('h2');
    title.innerHTML = 'Désinscription';
    deletionFormElement.appendChild(title);

    const params = new URLSearchParams({
      frame: currentTarget.dataset.frame,
      day_index: currentTarget.dataset.dayIndex,
      hour_index: currentTarget.dataset.hourIndex,
      user_id: currentTarget.dataset.userId,
      from_user: true,
    });

    const deletionForm = document.createElement('form');
    deletionForm.action = `/missions/${
      currentTarget.dataset.missionId
    }/mission_calendar_shifts/${
      currentTarget.dataset.shiftId
    }/remove_user?${params.toString()}`;
    deletionForm.method = 'post';

    const hiddenInput = document.createElement('input');
    hiddenInput.type = 'hidden';
    hiddenInput.name = '_method';
    hiddenInput.value = 'delete';
    deletionForm.appendChild(hiddenInput);

    const deleteButton = document.createElement('input');
    deleteButton.type = 'submit';
    deleteButton.value = 'Me désinscrire de ce créneau';
    deletionForm.appendChild(deleteButton);

    deletionFormElement.appendChild(deletionForm);
    deletionFormElement.classList.remove('hidden');

    deleteButton.addEventListener('click', () => {
      deletionFormElement.classList.add('hidden');
    });
  }

  createClaimForm(currentTarget, formDivElement) {
    const title = document.createElement('h2');
    title.innerHTML = 'Réclamation';
    formDivElement.appendChild(title);
    const description = document.createElement('p');
    description.innerHTML =
      "Fais nous part d'un retard ou de temps de travail supplémentaire";
    formDivElement.appendChild(description);

    const form = document.createElement('form');
    form.action = `/missions/${currentTarget.dataset.missionId}/mission_calendar_shifts/${currentTarget.dataset.shiftId}/create_calendar_claim`;
    form.method = 'post';

    const div = document.createElement('div');
    div.style.display = 'flex';
    div.style.alignItems = 'center';
    div.style.gap = '0.5rem';
    div.style.margin = '1rem 0';
    form.appendChild(div);
    const absenceText = document.createElement('span');
    absenceText.classList.add('text');
    absenceText.innerHTML = 'Absence';
    div.appendChild(absenceText);
    const modifierSwitch = document.createElement('input');
    modifierSwitch.type = 'checkbox';
    modifierSwitch.id = 'calendar_claim_switch';
    modifierSwitch.name = 'calendar_claim[switch]';
    modifierSwitch.classList.add('calendar__claim-switch');
    div.appendChild(modifierSwitch);
    const modifierSwitchLabel = document.createElement('label');
    modifierSwitchLabel.id = 'calendar_claim[switch]';
    modifierSwitchLabel.name = 'modifier_claim[switch]';
    modifierSwitchLabel.setAttribute('for', 'calendar_claim_switch');
    modifierSwitchLabel.classList.add('calendar__claim-switch-label');
    div.appendChild(modifierSwitchLabel);
    const extraTimeText = document.createElement('span');
    extraTimeText.classList.add('text');
    extraTimeText.innerHTML = 'Heure sup';
    div.appendChild(extraTimeText);
    const claimType = document.createElement('input');
    claimType.type = 'hidden';
    claimType.value = 'absence';
    claimType.id = 'calendar_claim_type';
    claimType.name = 'calendar_claim[claim_type]';
    form.appendChild(claimType);
    const inputLabel = document.createElement('label');
    inputLabel.id = 'calendar_claim[comment]';
    inputLabel.name = 'calendar_claim[comment]';
    inputLabel.setAttribute('for', 'calendar_claim_comment');
    inputLabel.innerHTML = 'Description';
    form.appendChild(inputLabel);
    const input = document.createElement('input');
    input.type = 'text';
    input.id = 'calendar_claim_comment';
    input.name = 'calendar_claim[comment]';
    input.placeholder = 'Décris nous ta demande (min. 10 caractères)';
    form.appendChild(input);
    const minutesLabel = document.createElement('label');
    minutesLabel.id = 'calendar_claim[duration]';
    minutesLabel.name = 'calendar_claim[duration]';
    minutesLabel.setAttribute('for', 'calendar_claim_duration');
    minutesLabel.innerHTML = 'Durée en minutes de ton absence';
    form.appendChild(minutesLabel);
    const minutes = document.createElement('input');
    minutes.type = 'number';
    minutes.id = 'calendar_claim_duration';
    minutes.name = 'calendar_claim[duration]';
    minutes.min = 0;
    form.appendChild(minutes);
    const submitButton = document.createElement('input');
    submitButton.type = 'submit';
    submitButton.value = 'Faire une réclamation';
    submitButton.disabled = true;
    submitButton.classList.add('button--disabled');
    form.appendChild(submitButton);

    formDivElement.appendChild(form);
    formDivElement.classList.remove('hidden');

    submitButton.addEventListener('click', () => {
      formDivElement.classList.add('hidden');
    });
    input.addEventListener('input', () => {
      this.checkClaimInputs(input, minutes, submitButton);
    });

    minutes.addEventListener('input', () => {
      this.checkClaimInputs(input, minutes, submitButton);
    });

    modifierSwitch.addEventListener('change', () => {
      this.changeClaimType(modifierSwitch, claimType, minutesLabel);
    });
  }

  checkClaimInputs(input, minutes, submitButton) {
    if (minutes.value < 0) minutes.value = 0;

    if (input.value.length >= 10 && minutes.value >= 1) {
      submitButton.disabled = false;
      submitButton.classList.remove('button--disabled');
    } else {
      submitButton.disabled = true;
      submitButton.classList.add('button--disabled');
    }
  }

  changeClaimType(modifierSwitch, claimType, minutesLabel) {
    if (modifierSwitch.checked) {
      claimType.value = 'extra_time';
      minutesLabel.innerHTML = 'Durée en minutes du travail supplémentaire';
    } else {
      claimType.value = 'absence';
      minutesLabel.innerHTML = 'Durée en minutes de ton absence';
    }
  }

  // Methods below : Setup and update visual modification of current slot
  setupPreview({ target }) {
    document.getElementById('preview')?.remove();
    const previewDiv = document.createElement('div');
    previewDiv.style.boxSizing = 'border-box';
    previewDiv.style.borderWidth = '3px';
    previewDiv.style.borderStyle = 'solid';
    previewDiv.style.borderColor = '#00b4fc';
    previewDiv.style.position = 'absolute';
    previewDiv.style.zIndex = '2';
    previewDiv.style.width = '100%';
    previewDiv.style.transitionDuration = '200ms';
    previewDiv.style.top = target.style.top;
    previewDiv.id = 'preview';
    target.insertAdjacentElement('afterend', previewDiv);
  }

  updatePreview(startTime, endTime) {
    const preview = document.getElementById('preview');
    if (!preview) return;

    const height = (Math.round((endTime - startTime) / 60000) / 60) * 100;
    let borderCompensation = Math.max(
      0,
      Math.round((endTime - startTime) / 60000) / 60 - 1
    );
    borderCompensation = parseInt(borderCompensation);
    const initialDate = new Date(
      preview.previousElementSibling.dataset.startValue
    );
    const initialOffset = initialDate.getMinutes() === 30 ? 50 : 0;
    const topOffset =
      initialOffset + ((startTime - initialDate.getTime()) / 60000 / 60) * 100;
    preview.style.height = `calc(${height}% + ${borderCompensation}px)`;
    preview.style.top = `${topOffset}%`;
  }

  toggleModifiersAttributes({ currentTarget }) {
    this.modifierTargets.forEach((target) => {
      if (parseInt(currentTarget.value) === 0) {
        target.classList.remove('hidden');
      } else {
        target.classList.add('hidden');
      }
    });
  }
}
